import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container  className="home-about-section" id="about">
      <div className="home-about-div">
      <h1 className="heading-name-2">
           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate <span className="bolder">velit esse cillum dolore eu fugiat nulla pariatur.</span>
                {/* <strong className="main-name"> ULWOL</strong> */}
            </h1>
      </div>
    </Container>
  );
}
export default Home2;
