import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Team2 from "./Team2";
import Type from "./Type";

function Team() {
  return (
    <section>
      <Container fluid className="team-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={5} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
              Our Team{""}
                {/* <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span> */}
              </h1>

              <h1 className="heading-name">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                {/* <strong className="main-name"> ULWOL</strong> */}
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
               
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
             
            </Col>
          </Row>
        </Container>
      </Container>
      <Team2 />
    </section>
  );
}

export default Team;
