import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import ourteam from "../../Assets/ourteam.png";
import fotoKanan from "../../Assets/borderisi.png";
import fotoKiri from "../../Assets/borderisi2.png";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Team2() {
  return (
    <Container  className="home-about-section-team" id="about">
      <Row>
        <Col md={6} className="team-div">
          <div className="telephone-img">
            <img style={{width:"200px"}} src={ourteam}/>
          </div>
        </Col>
        <Col md={6} className="frame-right">
        </Col>
      </Row>
      <Row>
          <Col md={3} className="foto-team">
              <img style={{width:"360px"}} src={fotoKanan}/>
          </Col>
          <Col md={9} className="frame-right">
          <h1 className="heading-contact-1 bolder">
              Urna Neque
              {/* <strong className="main-name"> ULWOL</strong> */}
            </h1>
            <h2 className="heading-contact-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum varius duis at consectetur lorem donec massa sapien faucibus. Facilisis mauris sit amet massa vitae tortor. Sit amet justo donec enim diam vulputate ut pharetra. Eu mi bibendum neque egestas congue. Lectus arcu bibendum at varius vel pharetra vel turpis nunc. Vitae suscipit tellus mauris a diam maecenas. Risus quis varius quam quisque id diam. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Euismod nisi porta lorem mollis aliquam ut porttitor leo a.
            </h2>
        </Col>
        </Row>
        <Row>
          <Col md={9} className="frame-left">
          <h1 className="heading-contact-1 bolder">
              Urna Neque
              {/* <strong className="main-name"> ULWOL</strong> */}
            </h1>
            <h2 className="heading-contact-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum varius duis at consectetur lorem donec massa sapien faucibus. Facilisis mauris sit amet massa vitae tortor. Sit amet justo donec enim diam vulputate ut pharetra. Eu mi bibendum neque egestas congue. Lectus arcu bibendum at varius vel pharetra vel turpis nunc. Vitae suscipit tellus mauris a diam maecenas. Risus quis varius quam quisque id diam. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Euismod nisi porta lorem mollis aliquam ut porttitor leo a.
            </h2>
        </Col>
        <Col md={3} className="foto-team-kiri">
              <img style={{width:"360px"}} src={fotoKiri}/>
          </Col>
        </Row>
    </Container>
  );
}
export default Team2;
