import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import phone from "../../Assets/phoneicon.png";
import mail from "../../Assets/emailicon.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Contact2() {
  return (
    <Container  className="home-about-section" id="about">
        <Row>
          <Col md={6} className="frame-left">
            <h1 className="heading-contact-1 bolder">
              Dunia Dalam 
              <br/>
              Digital
              {/* <strong className="main-name"> ULWOL</strong> */}
            </h1>
            <h2 className="heading-contact-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore 
            </h2>
          </Col>
          <Col md={6} className="frame-right">
          <h1 className="heading-contact-1 bolder">
              Kantor Pusat
              <br/>
              Dunia Dalam Digital
              {/* <strong className="main-name"> ULWOL</strong> */}
            </h1>
            <h2 className="heading-contact-2">
              The City Tower Lantai 11,
              <br/>
              Jl. M.H. Thamrin No.81,
              <br/>
              Jakarta Pusat, Indonesia 10310
            </h2>
        </Col>
        </Row>
        <br/>
        <Row>
            <Col md={6} className="frame-left">&nbsp;</Col>
            <Col md={6} className="telephone-div frame-right">
            <div className="telephone-img">
                <img src={phone}/>
              </div>
              <div className="telephone-text">
                <div style={{height:"40px",maxHeight:"40px",lineHeight:"1"}}>
                  <p className="mail-content">Telephone</p>
                  <span className="mail-content">(021)508 48010</span>
                </div>
              </div>
            </Col>
        </Row>
        <br/>
        <Row>
            <Col md={6} className="frame-left">&nbsp;</Col>
            <Col md={6} className="telephone-div frame-right">
              <div className="telephone-img">
                <img src={mail}/>
              </div>
              <div className="telephone-text">
                <div style={{height:"40px",maxHeight:"40px",lineHeight:"1"}}>
                  <p className="mail-content">Email</p>
                  <span className="mail-content">contact.us@duniadalamdigital.com</span>
                </div>
              </div>
            </Col>
        </Row>
          {/* <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/s.o.u.m.y.a_j.i.t/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col> */}
        
    </Container>
  );
}
export default Contact2;
